@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body { @apply bg-[#090909] text-white  font-sans   }



/* FIGMA DESIGN FONTS */
/* .top-heading{  @apply text-[64px]}

.h1 { @apply text-[48px] font-light; }
.h2 { @apply text-[40px] font-light; }
.h3 { @apply text-[36px] font-light; }
.h4 { @apply text-[32px] font-light; }
.h5 { @apply text-[28px] font-light; }
.h6 { @apply text-[24px] font-light; }
.h7 { @apply text-[24px] font-light; }
.h8 { @apply text-[20px] font-light; }
.h9 { @apply text-[18px] font-light; }
.h10 { @apply text-[14px] font-light; }

.b1 { @apply text-[32px] font-normal; }
.b2 { @apply text-[30px] font-normal; }
.b3 { @apply text-[28px] font-normal; }
.b4 { @apply text-[24px] font-normal; }
.b5 { @apply text-[22px] font-normal; }
.b6 { @apply text-[18px] font-normal; }
.b7 { @apply text-[16px] font-normal; }
.b8 { @apply text-[14px] font-normal; }
.b9 { @apply text-[12px] font-normal; }
.b10 { @apply text-[10px] font-normal; }

.c1 { @apply text-[28px]; }
.c2 { @apply text-[26px]; }
.c3 { @apply text-[24px]; }
.c4 { @apply text-[22px]; }
.c5 { @apply text-[20px]; }
.c6 { @apply text-[18px]; }
.c7 { @apply text-[16px]; }
.c8 { @apply text-[14px]; }
.c9 { @apply text-[12px]; }
.c10{ @apply text-[10px]; } */


/* RESPONSIVE FONTS  */

.top-heading { @apply lg:text-[64px] md:text-[56px] text-[48px];}
.h1 { @apply lg:text-[48px] md:text-[44px] text-[40px] font-light; }
.h2 { @apply lg:text-[40px] md:text-[36px] text-[32px] font-light; }
.h3 { @apply lg:text-[36px] md:text-[32px] text-[28px] font-light; }
.h4 { @apply lg:text-[32px] md:text-[28px] text-[24px] font-light; }
.h5 { @apply lg:text-[28px] md:text-[24px] text-[20px] font-light; }
.h6 { @apply lg:text-[24px] md:text-[20px] text-[18px] font-light; }
.h7 { @apply font-light text-[18px] md:text-[22px] lg:text-[24px]; }
.h8 { @apply font-light text-[16px] md:text-[18px] lg:text-[20px]; }
.h9 { @apply font-light text-[14px] md:text-[16px] lg:text-[18px]; }
.h10 { @apply font-light text-[12px] md:text-[14px] lg:text-[16px]; }

.b1 { @apply lg:text-[32px] md:text-[28px] text-[24px] font-normal; }
.b2 { @apply lg:text-[30px] md:text-[26px] text-[22px] font-normal; }
.b3 { @apply lg:text-[28px] md:text-[24px] text-[20px] font-normal; }
.b4 { @apply lg:text-[24px] md:text-[20px] text-[18px] font-normal; }
.b5 { @apply lg:text-[22px] md:text-[18px] text-[16px] font-normal; }
.b6 { @apply lg:text-[18px] md:text-[16px] text-[14px] font-normal; }
.b7 { @apply font-normal text-[14px] md:text-[15px] lg:text-[16px]; }
.b8 { @apply font-normal text-[12px] md:text-[13px] lg:text-[14px]; }
.b9 { @apply font-normal text-[10px] md:text-[11px] lg:text-[12px]; }
.b10 { @apply font-normal text-[8px] md:text-[9px] lg:text-[10px]; }

.c1 { @apply lg:text-[28px] md:text-[24px] text-[20px]; }
.c2 { @apply lg:text-[26px] md:text-[22px] text-[18px]; }
.c3 { @apply lg:text-[24px] md:text-[20px] text-[16px]; }
.c4 { @apply lg:text-[22px] md:text-[18px] text-[16px]; }
.c5 { @apply lg:text-[20px] md:text-[16px] text-[14px]; }
.c6 { @apply lg:text-[18px] md:text-[14px] text-[12px]; }
.c7 { @apply text-[12px] md:text-[14px] lg:text-[16px]; }
.c8 { @apply text-[10px] md:text-[12px] lg:text-[14px]; }
.c9 { @apply text-[8px] md:text-[10px] lg:text-[12px]; }
.c10 { @apply text-[6px] md:text-[8px] lg:text-[10px]; }





.btn-link{
  @apply w-full transition-all duration-300   bg-white text-black px-4 py-2 rounded-xl focus:ring-2 ring-white/30 
}
.btn-link-inverted{
  @apply w-full transition-all duration-300  border   bg-black text-white px-4 py-2 rounded-xl focus:ring-2 ring-white/30 
}
.nav-link{
  
  @apply text-primary-100   p-3

}

.nav-link-active{

  @apply text-white h10 bg-white/[0.15] p-3 rounded-lg

}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  border: none;
  box-shadow: none;
}
input[type="range"]:focus::-webkit-slider-thumb {
  outline: none;
}
input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: white;
  border: none;
  border-radius: 50%;
}
input[type="range"]::-ms-thumb {
  width: 12px;
  height: 12px;
  background: white;
  border: none;
  border-radius: 50%;
}

